<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
          </div>
        </div> 
        <ul class="listBox">
          <li><span>代付订单号</span><i class="hidden1">{{detail.mchPayNo}}</i></li>
          <li><span>代付流水号</span><i class="hidden1">{{detail.plfPayNo}}</i></li>
          <li><span>三方代付订单号</span><i class="hidden1">{{detail.thdPayNo}}</i></li>
          <li style="height: 19px;"></li>
          <li><span>商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>商户编号</span><i class="hidden1">{{detail.mchId}}</i></li>
          <li><span>所属机构</span><i class="hidden1">{{detail.orgName}}</i></li>
          <li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime}}</i></li>
          <li><span>交易时间</span><i class="hidden1">{{detail.tradeTime}}</i></li>
          <li><span>产品类型</span>
             <i v-if="detail.payType == 'BANK_PAY_DAILY_ORDER'">朝夕付</i>
            <i v-if="detail.payType == 'BANK_PAY_MAT_ENDOWMENT_ORDER'">任意付</i>
          </li>
          <li><span>交易状态</span>
            <i v-if="detail.payStatus == 'PAYING'">出款中</i>
            <i v-if="detail.payStatus == 'FAIL'">代付失败</i>
            <i v-if="detail.payStatus == 'REACHED'">已到账</i>
          </li>
          <li><span>账户卡号</span><i class="hidden1">{{detail.accountNo}}</i></li>
          <li><span>账户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.accountName" placement="top">
              <b>{{detail.accountName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>交易金额</span><i class="hidden1">{{detail.payAmount? detail.payAmount/100:'' | formatMoney}}</i></li>
          <li><span>手续费</span><i class="hidden1">{{detail.fee?detail.fee /100:'' | formatMoney}}</i></li>
          <li><span>总金额</span><i class="hidden1">{{detail.totalAmount? detail.totalAmount/100:'' | formatMoney}}</i></li>
          <li><span>代付用途</span>
					  <i v-if="detail.paidUse == '201'">工资资金</i>
					  <i v-if="detail.paidUse == '202'">活动经费</i>
					  <i v-if="detail.paidUse == '203'">养老金</i>
					  <i v-if="detail.paidUse == '204'">货款</i>
					  <i v-if="detail.paidUse == '205'">劳务费</i>
					  <i v-if="detail.paidUse == '206'">保险理财</i>
					</li>
          <li><span>说明</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.body" placement="top">
              <b>{{detail.body}}</b>
            </el-tooltip>
          </i></li>
          <li><span>通知地址</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.notifyUrl" placement="top">
              <b>{{detail.notifyUrl}}</b>
            </el-tooltip>
          </i></li>
          <li><span>代付应答码</span><i class="hidden1">{{detail.resultCode}}</i></li>
          <li><span>应答码描述</span><i>
            <el-tooltip class="item" effect="light" :content="detail.resultMessage" placement="top">
              <b>{{detail.resultMessage}}</b>
            </el-tooltip>
          </i></li>
          <li><span>异常错误码</span><i class="hidden1">{{detail.errorCode}}</i></li>
          <li><span>错误描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.errorMessage" placement="top">
              <b>{{detail.errorMessage}}</b>
            </el-tooltip>
          </i></li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">代付通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
						<td>
						  <p>状态码</p>
						</td>
						<td>
						  <p>返回信息</p>
						</td>
          </tr>
          <tr v-for="(item,index) in notices" :key="index">
            <td>
              <p>{{item.notifyTime}}</p>
            <td>
              <p v-if="item.notifyType == 'PLATFORM'">平台</p>
              <p v-if="item.notifyType == 'THIRD'">第三方</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">未响应</p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
						<td>
						  <p>{{item.resultCode}}</p>
						</td>
						<td>
						  <p>{{item.resultMessage}}</p>
						</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
 import {
    agnpayDetail
	} from '@/api/operation/inform.js'
  export default {
    data() {
      return {
        crumbs: "交易详情",
        notices:[],
        detail:{}
      }
    },
    created(){
      this.getLists();
    },
    activated(){
      this.getLists();
    },
    methods: {
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
      // 获取列表
      getLists(){
        agnpayDetail(this.$route.query.payId).then(res=>{
          let resOther = res.resultData.detail
          for(let item in resOther){
            if(resOther[item] == null||resOther[item] == ''){
              resOther[item] = ''
            }
          }
          this.detail = resOther
          this.notices = res.resultData.notices
        }).catch()

      }
    }
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
